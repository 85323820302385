export const TABLE_HEANDING = ['Место', 'Имя', 'Телефон'];
export const POSITION_KEY = {
    front: 'VIP',
    left: 'Слева',
    mid: 'Центр',
    right: 'Справа',
};

export const CONTENT_SERVICES = [
    {
        header: 'Пунктуальность',
        text: 'С нами легко планировать свой день, ведь мы всегда довезем вас во время'
    },
    {
        header: 'Опыт',
        text: 'Мы работаем в сегменте Ride-sharing Servis уже более 5 лет'
    },
    {
        header: 'Доверие',
        text: 'Более 70 тысяч пассажиров воспользовались нашим сервисом'
    },
    {
        header: 'Безопастность',
        text: 'У наших водителей большой опыт, а автомобили проходят ТО перед каждой поездкой'
    },
    {
        header: 'Комфорт',
        text: 'К вашим услугам уютный салон автомобилей класса Comfort'
    },
    {
        header: 'Атмосфера',
        text: 'У наших поездок всегда прекрасная атмосфера'
    },
];

export const CONTENT_TITLE = {
    titleHeader: 'Сервис по перевозке и доставке',
    titleSpan: 'Нашими услугам уже воспользовались',
    titleText: ['Осуществляем перевозки по маршрутам', 'Бакал-Сатка-Челябинск и Челябинск-Сатка-Бакал']
};

export const PATH_LENGTH = 1350;