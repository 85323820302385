import Calendar from "../../components/calendar/Calendar";
import Nav from "../../components/nav/Nav";

export default function AdminPage() {

    return (
        <div>
            <Nav />
            <Calendar />
        </div >
    )
}